<template>
  <div>
    <van-popup
      v-model="dialogVisible"
      closeable
      position="bottom"
      :style="{ height: '80%' }"
    >
      <section class="section section--last">
        <div class="zf_order">
          <div>
            <div class="col-12">
              <h4>支付订单</h4>
              <div class="order">
                <span
                  >订单号：<span id="copy_money">{{
                    this.payData.payNo
                  }}</span></span
                >
              </div>
              <div class="goods_name">
                <span>商品名称：{{ this.payData.payName }}</span>
              </div>
              <br />
              <div class="price mt-2">
                <span>{{ this.payData.payAmount }}</span>
                <span>元</span>
              </div>
              <br />
              <!-- 二维码 -->
              <div id="qrcode" align="center">
                <div id="qrcode" ref="qrCodeDiv"></div>
              </div>

              <br />
              <div class="price mt-1">
                <span style="color: red"></span>

                <span style="color: red"
                  >请付款 {{ this.payData.payAmount }} 元<br />
                </span>
                <!-- <span id="divTime" style="color: red"
                  >二维码有效时间:<small style="color: red; font-size: 26px"
                    >01</small
                  >分<small style="color: red; font-size: 26px">09</small
                  >秒,失效勿付</span
                > -->
              </div>
              <el-button type="primary" size="medium" @click="ConfirmPayment()">
                我已支付
              </el-button>
              <div class="shanxinzha">
                <span>{{ this.payData.paymentText }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </van-popup>

    <van-dialog
      title="支付提示"
      v-model="dialogPayTipVisible"
      show-cancel-button
      confirmButtonText="我已支付"
      cancelButtonText="取消支付"
      @confirm="ConfirmPayment()"
      :before-close="newGroupBefColse"
    >
      <div style="padding: 20px; text-align: center">请确认支付信息</div>
    </van-dialog>
  </div>
</template>

<script>
import { pay, statusPaid } from "@/api/order";

import { Toast } from "vant";
import QRCode from "qrcodejs2";

export default {
  name: "Payment",
  props: {
    //支付信息
    payConfig: {
      type: Object,
    },
    orderId: {
      type: Number, //订单Id
      default: 0,
    },

    orderParams: {
      type: Number, //订单参数
      default: 0,
    },
    deliveryRemark: {
      type: Number, //订单备注
      default: 0,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogPayTipVisible: false,
      dialogVisible: false,
      payData: {},
    };
  },
  methods: {
    newGroupBefColse(action, done) {
      if (action == "confirm") {
        done(false);
      } else {
        done();
      }
    },

    //在线支付
    toPayment() {
      //余额支付
      if (this.payConfig === "balance") {
        let data = {
          id: this.orderId,
          orderParams: this.orderParams,
          deliveryRemark: this.deliveryRemark,
          paymentMethod: "balance",
        };
        new Promise((resolve, rejust) => {
          pay(data)
            .then((res) => {
              if (res.status == 0) {
                Toast.success({
                  message: "付款成功",
                });

                // 付款成功处理
                setTimeout(() => {
                  if (this.isMobile) {
                    this.$router.push({ path: "order" });
                  } else {
                    this.$router.push({ path: "porder" });
                  }
                }, 300);
              }
              resolve();
            })
            .catch((error) => {
              rejust(error);
            });
        });
      } else {
        let data = {
          id: this.orderId,
          orderParams: this.orderParams,
          deliveryRemark: this.deliveryRemark,
          payId: this.payConfig.payId,
          paymentMethod: "online",
        };

        //在线支付
        new Promise((resolve, rejust) => {
          pay(data)
            .then((res) => {
              if (res.status == 0) {
                this.payData = res.result;
                var url = this.payData.payUrl;
                var qrCode = this.payData.qrCode;

                //当面付特殊处理
                if (this.payConfig.payType === "aliFacePay") {
                  if (this.isMobile) {
                    // 获取第三方URL
                    window.location.href = url;
                    this.dialogPayTipVisible = true;
                  } else {
                    this.dialogVisible = true;
                    this.GenerateQRCode(qrCode);
                    // this.loopOrderState();
                  }
                } else {
                  // 延时执行的操作
                  // window.open(url, "_blank");
                  setTimeout(() => window.open(url, "_blank"));

                  this.dialogPayTipVisible = true;

                  //true 是手机访问
                  // if (url) {
                  //   window.open(url, "_blank");
                  //   this.dialogPayTipVisible = true;
                  // } else {
                  //   this.dialogVisible = true;
                  //   this.GenerateQRCode(qrCode);
                  //   this.loopOrderState();
                  // }
                }
              }
              resolve();
            })
            .catch((error) => {
              rejust(error);
            });
        });
      }
    },

    /**
     * 确认支付
     */
    ConfirmPayment() {
      this.loopOrderState();
    },

    // 生成二维码
    GenerateQRCode(qrCode) {
      //生成之前清除二维码
      this.delQrcode();
      var that = this;
      setTimeout(() => {
        new QRCode(that.$refs.qrCodeDiv, {
          text: qrCode,
          width: 200,
          height: 200,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      }, 0);
    },

    //轮询当前订单状态 默认轮询10次
    loopOrderState() {
      new Promise((resolve, rejust) => {
        statusPaid({ id: this.orderId })
          .then((res) => {
            if (res.result == 1) {
              Toast.success({
                message: "付款成功",
              });
              // 付款成功处理
              setTimeout(() => {
                if (this.isMobile) {
                  this.$router.push({ path: "order" });
                } else {
                  this.$router.push({ path: "porder" });
                }
              }, 300);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 清空二维码,避免生成多个二维码
    delQrcode() {
      setTimeout(() => {
        this.$refs.qrCodeDiv.innerHTML = "";
      }, 0);
    },
  },
};
</script>

<style>
.section .zf_order {
  margin: 18px auto 0;
  /* padding-top: 38px; */
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.section .con {
  margin: 3px auto 0;
  padding-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}

.section .zf_order .col-12 .order {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: center;
}
.section .zf_order .col-12 .order span:first-child {
  color: #999;
  font-size: 15px;
  margin-left: 14px;
}
.section .zf_order .col-12 .order span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.goods_name {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}
.goods_name span:nth-child(2) {
  margin-left: 14px;
}
.yuanmoney {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: left;
}
.yuanmoney span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.pay_type {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.pay_type img {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
}
.pay_type span {
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
}
.code_cs,
.code {
  height: 208px;
  background: #fbfbfb;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
}
.code_cs {
  height: 208px;
  background: #ffffff;
}
.code_cs img {
  position: absolute;
  width: 49px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  padding: 10px;
}
.code {
  border: 5px solid #d8d1d1;
  border-radius: 5px;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
  display: block;
  padding: 3px;
}
.price {
  color: #386cfa;
  width: 100%;
  text-align: center;
  top: 65px;
}
.price span:first-child {
  font-size: 28px;
}
.price span {
  font-weight: 700;
}
.price span:nth-child(2) {
  font-size: 17px;
}
.price span {
  font-weight: 700;
}
.shanxinzha {
  margin-top: 32px;
  width: 100%;
  text-align: center;
}
.shanxinzha img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  /* -webkit-animation: xuanzhuan 0.8s linear infinite; */
}

.shanxinzha span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.shanxinzha span p {
  display: inline-block;
  color: #386cfa;
}
.section--last {
  margin-bottom: 20px;
}

/* 新支付页面 */
.qr-order-content {
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  padding-top: 23px;
}
.qr-order-content .bt {
  width: 100%;
  height: 64px;
  background-color: #eaf2ff;
  color: #0a72ff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 64px;
}

.qr-order-content .box1 {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  /* margin-bottom: 80px;
  padding-bottom: 80px; */
  margin: 20px 0 40px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 5px 10px rgb(0 0 0/5%);
  display: flex;
}

.qr-order-content .box1-1 {
  /* width: 644px; */
  margin: 0 auto;
  /* margin-top: 42px; */
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.qr-order-content .box1-1 ul li {
  line-height: 35px;
  font-size: 16px;
  color: #333333;
}

.qr-order-content .box1-2 {
  /* width: 644px; */
  margin: 0 auto;
  margin-top: 42px;
  overflow: hidden;
}
.qr-order-content .box1-2 ul {
  width: 100%;
}
.qr-order-content .box1-2 ul li {
  float: left;
  width: 131px;
  height: 42px;
  margin-right: 6px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 14px;
  color: #333333;
  line-height: 42px;
  margin-top: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.box1-3 {
  /* width: 644px; */
  overflow: hidden;
  margin: 0 auto;
  /* margin-top: 50px; */
}

.qr-order-content .box1-1 ul li {
  margin-bottom: 10px;
  list-style: none;
}

.qr-order-content .box1-1 ul li span.label {
  display: inline-block;
  width: 90px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.qr-order-content .box1-1 ul li span.value {
  color: #5e72e4;
  font-size: 16px;
  font-weight: bold;
}

.order-content {
  width: 100%;
  margin-top: 20px;
  background: #f9f9fa;
}

.order-content .goods-detail {
  padding: 5px;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
}

.order-content .goods-detail .add-info-title,
.order-content .goods-detail .by-info-title,
.order-content .goods-detail .title {
  color: #303133;
  padding: 16px;
  margin: 0 10px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
  position: relative;
  margin-left: 20px;
}

.order-content .goods-detail .add-info-title:before,
.order-content .goods-detail .by-info-title:before,
.order-content .goods-detail .title:before {
  content: "";
  height: 20px;
  width: 5px;
  background: #2b6fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 1px;
  border-radius: 0.18rem;
  transform: translateY(-50%);
}

.order-content .goods-detail .qr-order-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 180px;
  margin: 0 auto;
  width: 98%;
  padding: 0 0.56rem;
  background: #f9f9fa;
  border-radius: 0.18rem;
}

.order-content .goods-detail .qr-order-content span.goods-type {
  position: absolute;
  top: 0;
  right: 0;
  width: 160px;
  height: 40px;
  background: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-radius: 0 10px 0 10px;
}

.order-content .goods-detail .qr-order-content .el-image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-right: 10px;
}

.order-content .goods-detail .qr-order-content .row-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-content .goods-detail .qr-order-content .conter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.9rem;
  border-radius: 0.1rem;
  border: 1px solid #dcdfe6;
  overflow: hidden;
}

.order-content .goods-detail .qr-order-content .conter:hover {
  border-color: #1890ff;
}

.order-content .goods-detail .qr-order-content .conter .conter-input {
  display: block;
  height: 100%;
  width: 2.5rem;
  font-size: var(--sizeM, 0.25rem);
  outline: none;
  border: none;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
}

.order-content .goods-detail .qr-order-content .conter .icon {
  width: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  height: 100%;
  cursor: pointer;
}

.order-content .goods-detail .qr-order-content .conter .icon:hover {
  color: #1890ff;
}

.order-content .goods-detail .el-form {
  background: #f9f9fa;
  width: 98%;
  margin: 0 auto;
  border-radius: 0.1rem;
}

.order-content .goods-detail .el-form .el-form-item .tips {
  font-size: var(--sizeXL, 0.35rem);
  color: grey;
  margin: 0 5px;
}

.order-content .goods-detail .add-info-content {
  display: flex;
}

.order-content .goods-detail .add-info-content .el-form {
  padding-top: 4px;
}

.order-content .goods-detail .by-info-content {
  width: 98%;
  padding: 0.36rem;
  padding-left: 0;
  margin: 0 auto;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  border-top: 0.018rem solid #ddd;
}

.order-content .goods-detail .by-info-content .pay-computed {
  width: 20%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
}

.order-content .goods-detail .by-info-content .pay-computed .el-row {
  margin: 15px auto;
}

.order-content .goods-detail .by-info-content .pay-computed .el-row .sub-btn {
  width: 4.44rem;
  height: 0.89rem;
  background: #1890ff;
  border-radius: 0.1rem;
  font-size: var(--sizeXL, 0.35rem);
}

.order-content
  .goods-detail
  .by-info-content
  .pay-computed
  .el-row:first-child {
  color: #999;
  text-decoration: line-through;
}

.order-content .goods-detail .by-info-content .by-info-content-input {
  display: flex;
  font-size: var(--sizeM, 0.25rem);
  margin: 0 0.18rem;
}

.order-content .goods-detail .choose-pay {
  display: flex;
  align-items: center;
  padding: 22px 15px;
  gap: 16px;
}

.order-content .goods-detail .choose-pay p {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
}

.order-content .goods-detail .choose-pay .choose-pay-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  width: 160px;
  height: 50px;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item .svg {
  width: 26px;
  height: 26px;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item p {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
}

.order-content .goods-detail .choose-pay .choose-pay-item .active {
  border-color: #1890ff;
}

.order-content .goods-detail .choose-pay .choose-pay-item label {
  white-space: nowrap;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pass-box {
  width: 400px;
}

.order_spacing {
  margin-top: 20px;
}
</style>
