<template>
  <div>
    <div v-if="!detailStatus">
      <van-empty description="商品不存在">
        <van-button
          round
          type="danger"
          style="width: 120px"
          @click="goBackPage()"
        >
          返回
        </van-button>
      </van-empty>
    </div>
    <div class="ranta-container-0" v-else>
      <div class="ranta-container-0-0">
        <div class="ranta-container-0-0-0">
          <div>
            <div class="head-wrapper">
              <div class="head-menu">
                <div class="iconfont">
                  <van-icon name="arrow-left" size="20" @click="goBackPage()" />
                </div>
                <div class="iconfont">
                  <van-icon name="wap-home-o" size="21" @click="goBackHome()" />
                </div>
              </div>
            </div>

            <van-swipe
              :autoplay="3000"
              indicator-color="white"
              style="height: 100%"
            >
              <van-swipe-item
                v-for="(v, k, i) in productDetailList.pictureList"
                :key="i"
                @click="handleImageClick(productDetailList.pictureList)"
              >
                <img
                  :src="v.url"
                  style="display: block; width: 100%; height: 100%"
                />
              </van-swipe-item>
            </van-swipe>
          </div>

          <!-- 价格 -->
          <div>
            <div>
              <div class="m-base-info">
                <div class="m-title-block">
                  <div class="goods-title u-base-info-row">
                    <div class="goods-title__tags"></div>
                    <div class="goods-title__box">
                      <div class="goods-title__main">
                        <span class="goods-title__main-text">
                          {{ productDetailList.productName }}</span
                        >

                        <div
                          style="
                            color: #8c8c8c;
                            font-size: 14px;
                            padding: 5px 0px 5px 0px;
                          "
                        >
                          {{ productDetailList.productSubtitle }}
                        </div>
                      </div>
                      <div class="goods-title__more-wrapper">
                        <div class="goods-title__more" @click="shareProducts()">
                          <div
                            class="vant-tee van-icon van-icon-share"
                            style="
                              color: rgb(100, 101, 102);
                              font-size: 18px;
                              display: block;
                            "
                          ></div>
                          <div class="goods-title__more-item-word">分享</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="m-price-block">
                  <div class="goods-price u-base-info-row">
                    <div class="goods-price__current">
                      <span
                        style="font-weight: 500"
                        v-html="$decimalPrice(productDetailList.price)"
                      ></span>

                      <!-- 原价 -->
                      <div
                        class="goods-price__origin u-base-info-row"
                        v-if="productDetailList.parValue"
                      >
                        <div
                          class="goods-price__origin-price"
                          style="
                            color: #c4c7cf;
                            font-weight: 400;
                            font-size: 0.8125rem;
                            margin-left: 5px;
                          "
                        >
                          ￥{{ productDetailList.parValue }}
                        </div>
                      </div>
                    </div>

                    <!-- 标签 -->
                    <div style="position: absolute; top: 0; right: 0">
                      <van-tag
                        type="primary"
                        size="medium"
                        style="border-radius: 5px"
                      >
                        {{ productDetailList.productTypeText }}
                      </van-tag>
                    </div>
                  </div>
                </div>

                <!-- 等级差价展示 -->
                <div
                  v-if="setting.levelPrice"
                  class="svipCon acea-row row-between-wrapper skeleton-rect"
                  @click="openLevelVisible"
                >
                  <div class="acea-row row-between-wrapper">
                    <img
                      style="width: 24px; height: 24px; margin-right: 5px"
                      src="@/assets/level_icon.png"
                      draggable="false"
                    />
                    <div>提升等级 享受更低折扣</div>
                  </div>
                  <div class="svipBtn">立即升级</div>
                </div>

                <!-- 规格 -->
                <div
                  class="mobile-skuCate"
                  v-if="productDetailList.skuList.length > 0"
                >
                  <span class="mobile-skuCateText mobile-SkuCateText">
                    商品规格：
                  </span>
                  <div class="mobile-skuItemWrapper">
                    <div
                      class="mobile-skuItem"
                      v-for="(v, k, i) in productDetailList.skuList"
                      :key="i"
                      :class="{ sku_select: selectSkuId === v.productId }"
                      @click="selectSkuId = v.productId"
                    >
                      <div @click="toProductDetails(v.productId)">
                        <img
                          class="mobile-skuIcon"
                          :src="v.productImg"
                          style="opacity: 1; object-fit: contain"
                        />
                        <span class="mobile-skuValueName">
                          {{ v.skuKeyName }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 价格展示 -->
                <div>
                  <div class="detail_label acea-row row-between-wrapper">
                    <div class="skeleton-rect">
                      市场价:
                      <span v-if="productDetailList.parValue">
                        {{ productDetailList.parValue }}
                      </span>
                      <span v-else>***</span>
                    </div>

                    <div
                      class="skeleton-rect"
                      v-if="productDetailList.inventoryState"
                    >
                      {{ productDetailList.inventoryState }}
                    </div>

                    <div
                      class="skeleton-rect"
                      v-if="productDetailList.salsCount"
                    >
                      已售:
                      <span style="color: rgb(76, 175, 80)">
                        {{ productDetailList.salsCount }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="
                  background: #fff;
                  text-align: center;
                  border-top: 1px solid #f5f5f5;
                "
              >
                <div style="padding: 10px; font-size: 12px; color: #969799">
                  <van-row gutter="10">
                    <van-col span="6">
                      <div>
                        <img src="@/assets/image/icon_zheng.svg" width="25px" />
                        <span class="icon_name">正品保证</span>
                      </div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <img
                          src="@/assets/image/icon_shouhou.svg"
                          width="25px"
                        />
                        <span class="icon_name">品质溯源</span>
                      </div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <img src="@/assets/image/icon_jisu.svg" width="25px" />
                        <span class="icon_name">专属客服</span>
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div>
                        <img
                          src="@/assets/image/icon_anquan.svg"
                          width="25px"
                        />
                        <span class="icon_name">平台保证</span>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <!-- 服务 -->
          <!-- <div>
            <div class="group-block">
              <div class="service-block">
                <div class="van-cell">
                  <div class="van-cell__title">
                    <div class="sc__main sc__main--ellipsis">
                      <span class="tee-text sc__title">温馨提示</span>
                      <span>请确保账号输入无误，充值成功后不支持退款</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- 商品详情 -->

          <div>
            <div>
              <div class="goods-price-intro">
                <div class="title">
                  <div class="white-block">商品详情</div>
                </div>
              </div>
              <div style="background-color: #ffffff">
                <div>
                  <van-empty
                    description="暂无商品描述~"
                    v-if="!productDetailList.productDescription"
                  />
                  <div class="cap-richtext" v-else>
                    <span v-html="productDetailList.productDescription"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 充值提示 -->

    <!-- <van-popup
      v-model="popupTipVisible"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <p>{{ this.productDetailList.popupTip }}</p>
    </van-popup> -->

    <!-- 提交订单弹出框 -->
    <van-popup
      v-model="orderPopupStatus"
      closeable
      position="bottom"
      :style="{ height: '55%' }"
    >
      <section class="aui-scrollView">
        <div class="aui-pay-box">
          <div class="goodsInfo" style="padding: 15px">
            <van-card
              :price="productDetailList.price"
              :title="productDetailList.productName"
              :thumb="productDetailList.productImg"
            />
          </div>

          <van-form>
            <van-cell-group inset>
              <orderParam
                :orderParams="productDetailList.orderParams"
                v-model="productDetailList.orderParams"
              >
              </orderParam>
              <!-- <div
                v-for="(item, key, index) in this.productDetailList.orderParams"
                :key="index"
              >
                <van-field
                  v-model="item.value"
                  :name="item.name"
                  :label="item.name"
                  :placeholder="item.desc"
                />
              </div> -->

              <van-field name="stepper" label="购买数量">
                <template #input>
                  <van-stepper
                    input-width="100px"
                    v-model="orderForm.quantity"
                    :min="productDetailList.dealQuantityMin"
                    :max="productDetailList.dealQuantityMax"
                  />
                </template>
              </van-field>
            </van-cell-group>

            <div style="margin: 16px">
              <van-button
                round
                block
                type="danger"
                native-type="submit"
                @click="createOrder()"
              >
                提交订单￥{{
                  $calculateProductPrice(
                    orderForm.quantity,
                    productDetailList.price,
                    productDetailList.orderParams
                  )
                }}
              </van-button>
            </div>
          </van-form>
        </div>
      </section>
    </van-popup>

    <el-dialog
      destroy-on-close
      append-to-body
      :visible.sync="levelVisible"
      class="el_dialog_radius"
      width="90%"
      title="等级价格"
      custom-class="centered-dialog"
    >
      <div>
        <div style="margin-bottom: 20px">您当前的等级：{{ levelName }}</div>
        <el-table :data="levelData" border style="width: 100%">
          <el-table-column prop="levelName" label="等级" align="center">
          </el-table-column>
          <el-table-column prop="price" label="价格" align="center">
          </el-table-column>
        </el-table>

        <router-link to="/userlevel">
          <div style="padding: 15px">
            <van-button type="info" round block>立即升级</van-button>
          </div>
        </router-link>
      </div>
    </el-dialog>
    <!-- <van-dialog
      v-model="levelVisible"
      title="等级差价"
      confirm-button-text="关闭"
    >
      <div>
        <el-table border style="width: 100%">
          <el-table-column prop="date" label="日期"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
        </el-table>
      </div>
    </van-dialog> -->

    <van-dialog
      v-model="qrcodeVisible"
      title="商品分享"
      confirm-button-text="关闭"
    >
      <div class="mFenXiang" style="position: relative; top: 0px">
        <vue-canvas-poster
          :painting="painting"
          style="position: relative; top: 0px"
        ></vue-canvas-poster>
      </div>
    </van-dialog>

    <van-goods-action safe-area-inset-bottom>
      <van-goods-action-icon
        icon="shop-o"
        text="首页"
        @click="toIndex"
        class="custom-icon"
      />

      <van-goods-action-icon
        v-if="collectStatus"
        icon="like"
        text="已收藏"
        color="#FF638C"
        class="custom-icon"
      />

      <van-goods-action-icon
        v-else
        icon="like-o"
        text="收藏"
        @click="collectProducts"
        class="custom-icon"
      />

      <van-goods-action-icon
        icon="service"
        text="客服"
        @click="lianxikefu"
        class="custom-icon"
      />

      <!-- 直冲购买 -->
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="purchaseGoods"
      />
    </van-goods-action>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
// import { Toast } from "vant";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import { addCollectProducts, addBrowseProducts } from "@/api/product";
import orderParam from "@/components/mOrderParam.vue";
import { getToken } from "@/utils/auth";
import { queryProductDetail } from "@/api/index";

import { queryProductLevelPrice } from "@/api/product";

export default {
  components: {
    orderParam,
  },
  data() {
    return {
      levelData: [],
      levelVisible: false,
      selectSkuId: undefined,
      collectStatus: false,
      qrcodeVisible: false,
      painting: {
        width: "550px",
        height: "876px",
        background: require("@/assets/image/1703344720899.png"),
        views: [
          {
            type: "image",
            url: "",
            css: {
              top: "20px",
              left: "35px",
              borderRadius: "10px",
              width: "480px",
              height: "480px",
            },
          },
          // {
          //   type: "text",
          //   text: `邀你体验智一面`,
          //   css: {
          //     top: "80px",
          //     left: "170px",
          //     color: "#000000",
          //     fontSize: "16px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: `前端工程师`,
          //   css: {
          //     top: "220px",
          //     left: "36px",
          //     width: "500px",
          //     color: "#000000",
          //     fontSize: "60px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: `候选人专业技能评测！！！`,
          //   css: {
          //     top: "420px",
          //     left: "36px",
          //     width: "500px",
          //     color: "#000000",
          //     fontSize: "36px",
          //   },
          // },
          {
            type: "text",
            text: ``,
            css: {
              top: "520px",
              left: "36px",
              width: "480px",
              color: "#000000",
              fontSize: "22px",
            },
          },
          {
            type: "text",
            text: `2`,
            css: {
              top: "730px",
              left: "50px",
              width: "500px",
              color: "#000000",
              fontSize: "26px",
            },
          },
          {
            type: "qrcode",
            content: "",
            css: {
              bottom: "90px",
              right: "76px",
              color: "#000",
              background: "#fff",
              width: "100px",
              height: "100px",
              borderWidth: "5px",
              borderColor: "#fff",
            },
          },
          {
            type: "text",
            text: `微信扫码识别`, // 后端返回路径，这里特别坑，想象不到的bug，oss或者自己服务都允许跨域了，但在手机上还是会有出现cors跨域问题，可能是缓存问题，我们在路径上加个时间戳就好了0.0
            css: {
              bottom: "50px",
              right: "72px",
              color: "rgba(255,255,255,255)",
              fontSize: "18px",
            },
          },
          {
            type: "image",
            url: "",
            css: {
              width: "120px",
              height: "31px",
            },
          },
          // ....
        ],
      },
      popupTipVisible: false,

      kefuStatus: false,
      orderPopupStatus: false,
      productDetailList: [],
      orderForm: {
        //收件人姓名
        // deliveryPerson: null,
        //县编码
        // deliveryCountry: "",
        //省编码
        // deliveryProvince: null,
        //市编码
        // deliveryCity: null,
        //省名称
        // deliveryProvinceName: "",
        //城市名称
        // deliveryCityName: "",
        //地址
        // deliveryAddress: "",
        //手机号
        // deliveryPhone: "",
        //运费模版
        // deliveryConfigId: 1,
        // deliveryConfig: "",
        //成交平台 0:web 1:ios 2:android 3:wx
        // dealPlatform: 1,
        //备注
        // deliveryRemark: "",
        //邮编
        // deliveryPostcode: "",
        //充值账号
        rechargeAccount: "",
        //充值数量
        quantity: 1,
        //商品id
        productId: null,
        stockId: undefined,
      },

      orderParams: [],
      newProductId: undefined,

      //详情状态 true为正常 false
      detailStatus: true,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productDetail: (state) => state.index.productDetail,
      orderId: (state) => state.order.orderId,
      webSetting: (state) => state.index.webSetting,
      setting: (state) => state.index.setting,
      levelName: (state) => state.user.levelName,
    }),
  },

  watch: {
    "$route.params.id": function (newId, oldId) {
      // 检测到id参数变动时执行的操作
      if (newId !== oldId) {
        // 执行刷新参数的操作
        this.refreshData();
      }
    },
  },
  created() {
    // 执行刷新参数的操作
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.newProductId = this.$route.params.id;

      this.selectSkuId = this.$route.params.id;

      //获取获取商品详情
      this.getProductDetail();
      //进来就浏览商品
      this.browseProducts();
    },

    //获取商品详情
    async getProductDetail() {
      let data = { product_id: this.newProductId };

      const response = await queryProductDetail(data);

      if (response.result) {
        this.productDetailList = response.result;

        this.collectStatus = this.productDetailList.collectStatus;
        if (this.productDetailList.popupTip) {
          Dialog.alert({
            title: "充值提示",
            message: this.productDetailList.popupTip,
            theme: "round-button",
          }).then(() => {});
        }

        //设置网站标题
        document.title =
          this.productDetailList.productName +
          " - " +
          this.webSetting.detail.websiteName;
      } else {
        this.detailStatus = false;
      }
    },

    /**
     * 浏览商品
     */
    async browseProducts() {
      const hasToken = getToken();
      if (hasToken) {
        let data = { productId: this.newProductId };
        await addBrowseProducts(data);
      }
    },

    /**
     * 收藏商品
     */
    collectProducts() {
      let data = { productId: this.newProductId };
      new Promise((resolve, rejust) => {
        addCollectProducts(data)
          .then(() => {
            this.collectStatus = true;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 等级差价展示
     */
    openLevelVisible() {
      let data = { productId: this.newProductId };
      queryProductLevelPrice(data)
        .then((res) => {
          this.levelData = res.result;
          this.levelVisible = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },

    //联系客服
    lianxikefu() {
      this.$router.push("/contact");
    },

    /**
     * 点击放大图片
     */
    handleImageClick(imgList) {
      const resultList = [];
      for (const iterator of imgList) {
        resultList.push(iterator.url);
      }
      ImagePreview(resultList);
    },

    /**
     * 分享商品
     */
    shareProducts() {
      this.qrcodeVisible = true;
      this.painting.views[0].url = this.productDetailList.pictureList[0].url;
      this.painting.views[1].text = this.productDetailList.productName;
      this.painting.views[2].text =
        "￥" + this.productDetailList.price.toString();
      this.painting.views[3].content = window.location.href;
    },

    //提交订单,弹出框输入账号
    purchaseGoods() {
      this.orderPopupStatus = true;
    },

    //提交订单
    createOrder() {
      //判断充值账号是否为空
      if (
        this.orderForm.productType == "1" &&
        this.orderForm.rechargeAccount == ""
      ) {
        this.$message({
          message: "请填写充值账号",
        });
        return false;
      }

      this.orderForm.productId = this.newProductId;
      this.orderForm.orderParams = this.productDetailList.orderParams;
      this.orderForm.stockId = this.productDetailList.productStock.id;

      this.$store
        .dispatch("order/create", this.orderForm)
        .then(() => {
          this.$router.push({
            path: "/payment",
            query: { orderId: this.orderId },
          });
        })
        .catch(() => {
          // this.$message({
          //     message: '提交失败！',
          //     type: 'error'
          // });
        });
    },

    toIndex() {
      this.$router.push("/mindex");
    },

    //跳转商品详情界面
    toProductDetails(id) {
      // this.$router.push({
      //   path: "product_detail",
      //   query: { product_id: id },
      // });
      this.$router.push(`/product_detail/${id}`);
    },

    // touchEnd() {
    //   //手指离开
    //   clearTimeout(this.timer);
    // },

    // touchStart() {
    //   //手指触摸
    //   clearTimeout(this.timer); //再次清空定时器，防止重复注册定时器
    //   this.timer = setTimeout(() => {
    //     this.downloadIamge(this.qrcodeUrl64, "pic");
    //   }, 1000);
    // },

    /**
     * 返回上一页
     */
    goBackPage() {
      this.$router.go(-1);
    },

    /**
     * 返回主页
     */
    goBackHome() {
      this.$router.push("/mindex");
    },
  },
};
</script>
<style>
.ranta-container-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  padding-bottom: 85px;
  background-color: #f7f8fa;
  background-size: 100% auto;
  color: #323233;
}

.ranta-container-0-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.ranta-container-0-0-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.image-block.is-inited {
  width: 100%;
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* overflow: hidden; */
}

.image-block {
  position: relative;
  background-color: #f7f8fa;
  /* height: 375px; */
}

.image-block-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-swipe {
  height: 100%;
}

.tee-swipe {
  position: relative;
  /* -webkit-user-select: none; */
  user-select: none;
  overflow: hidden;
}

.tee-swipe__track {
  display: flex;
}

.tee-swiper-item {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
}

.image-swipe-item {
  max-width: 100%;
  object-fit: contain;
}

.m-base-info {
  padding: 0 16px 12px;
  background: #fff;
}

.m-price-block {
  position: relative;
  background-color: #fff;
  padding-top: 12px;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  line-height: 18px;
}

.u-base-info-row {
  font-size: 13px;
}

.goods-price {
  position: relative;
  text-align: left;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
}

.goods-price__current {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: end;
  /* -webkit-align-items: flex-end; */
  align-items: flex-end;
  margin-right: 8px;
  vertical-align: middle;
  font-size: 16px;
  color: var(--price, #323233);
}

.goods-price__current-label {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-right: 2px;
  font-weight: 700;
  position: relative;
}

.goods-price__current-price {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  line-height: 24px;
  font-weight: bolder;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  line-height: 18px;
}

.u-base-info-row {
  font-size: 13px;
}

.goods-price__origin {
  /* position: relative; */
  text-align: left;
  display: block;
  /* font-size: 12px; */
  color: #969799;
  /* line-height: 16px; */
  margin: 4px 0 0;
}

.goods-price__origin-label {
  margin-right: 4px;
}

.goods-price__origin-price {
  display: inline-block;
  text-decoration: line-through;
}

.m-title-block {
  position: relative;
  background-color: #fff;
  padding-top: 12px;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
}

.goods-title__tags:empty {
  margin-bottom: 0;
}

.goods-title__box {
  position: relative;
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -webkit-flex-direction: row; */
  flex-direction: row;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  min-height: 26px;
}

.goods-title__main {
  -webkit-box-flex: 1;
  /* -webkit-flex: 1; */
  flex: 1;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  font-size: 0;
  font-weight: 500;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
}

.goods-title__main-text {
  display: inline;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 20px;
  font-size: 16px;
  color: #333333;
  /* font-weight: 600; */
}

.goods-title__more-wrapper {
  position: relative;
  height: 100%;
  width: 24px;
  min-height: 36px;
}

.goods-title__more {
  position: absolute;
  left: 0;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  transform: translateY(-50%);
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* -webkit-flex-direction: column; */
  flex-direction: column;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  color: #969799;
}

.goods-title__more-item-word {
  margin-left: 2px;
  font-size: 12px;
  line-height: normal;
  white-space: nowrap;
}

.van-cell--center {
  /* -webkit-align-items: center; */
  align-items: center;
}

.van-cell {
  position: relative;
  /* display: -webkit-flex; */
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  padding: var(--cell-vertical-padding, 10px)
    var(--cell-horizontal-padding, 16px);
  /* font-size: 14px; */
  font-size: var(--cell-font-size, 14px);
  /* line-height: 24px; */
  line-height: var(--cell-line-height, 24px);
  /* color: #323233; */
  color: var(--cell-text-color, #323233);
  /* background-color: #fff; */
  background-color: var(--cell-background-color, #fff);
}

.van-cell__title:empty {
  display: none;
}

/* .van-cell__title {
  flex: 1;
} */

/* .van-cell__value {
  flex: 1;
} */

.van-cell__value {
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  /* color: #969799; */
  color: var(--cell-value-color, #969799);
}

.guarantee-bar__info {
  height: 24px;
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.guarantee-bar__logo.short-green {
  width: 59px;
  height: 16px;
}

.guarantee-bar__desc {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 6px;
  color: #969799;
  overflow: hidden;
}

.goods-stock-block {
  margin-top: 8px;
  font-size: 14px;
}

.goods-stock-block .stock-cell__main {
  display: flex;
  color: #323233;
}

.goods-stock-block .stock-cell__title {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
  margin-right: 12px;
  color: #969799;
}

.goods-stock-block .goods-stock {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  width: 100%;
}

.goods-stock-block .goods-stock .stock {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  color: #969799;
}

.goods-stock-block .goods-stock .stock .stock-content {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
}

.goods-stock-block .goods-stock .stock {
  /* display: -webkit-box;
	    display: -webkit-flex;
	    display: flex; */
  color: #969799;
}

.group-block {
  margin-top: 8px;
}

.service-block .sc__main--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.service-block .sc__main {
  /* display: -webkit-box;
	    display: -webkit-flex;
	    display: flex; */
  color: #323233;
}

.service-block .sc__title {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
  margin-right: 12px;
  color: #969799;
}

.cap-richtext {
  padding: 10px;
  overflow-x: hidden;
}

.cap-richtext img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.goods-price-intro {
  position: relative;
  box-sizing: border-box;
  font-size: 12px;
  color: #969799;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 8px;
}

.goods-price-intro .title {
  position: relative;
  margin: 0 16px;
  text-align: center;
  color: #323233;
  line-height: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.goods-price-intro .white-block {
  display: inline-block;
  height: 100%;
  background: #fff;
  padding: 0 8px;
  position: absolute;
  /* z-index: 1; */
  /* -webkit-transform: translateX(-50%); */
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: 600;
}

.goods-price-intro .icon,
.goods-price-intro .icon-rotate {
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #dcdee0;
  /* -webkit-transform: rotate(90deg);
	    transform: rotate(90deg);
	    -webkit-transition: -webkit-transform .3s;
	    transition: -webkit-transform .3s;
	    transition: transform .3s;
	    transition: transform .3s,-webkit-transform .3s; */
}

.goods-price-intro .content-show {
  height: auto;
  margin: 0 16px 16px;
}

.goods-price-intro .content {
  background-color: #f7f8fa;
  border-radius: 4px;
  /* height: 0; */
  box-sizing: border-box;
  /* -webkit-transition: all .3s; */
  transition: all 0.3s;
  overflow: hidden;
  /* margin: 0 16px; */
}

.goods-price-intro .block-top {
  margin-top: 12px;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

.goods-price-intro .text {
  display: inline;
  line-height: 20px;
  font-size: 12px;
  color: #333;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

.goods-price-intro .block--small {
  font-size: 12px;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

/* 顶部返回 */
.head .van-nav-bar__content {
  background-color: #ffffff;
}

.head .van-nav-bar__text {
  color: #000000;
  font-weight: 400;
}

/* .head .van-nav-bar .van-icon {
    color: #ffffff;
} */
/deep/.el-dialog {
  border-radius: 5px;
}

/deep/.el-dialog__body {
  padding: 0 !important;
}

.mFenXiang .canvas {
  position: relative;
  top: 0px;
  width: 100% !important;
  height: 100% !important;
}

/* 自己的 */
.product_detail_ts_title {
  font-size: 13px;
  color: #666;
  margin-right: 5px;
}

.product_detail_ts_value {
  font-size: 13px;
  color: rgb(76, 175, 80);
}

.icon_name {
  padding: 0 2px;
  font-weight: 400;
  color: #9d9d9d;
  font-size: 12px;
}

/* 商品详情返回 */
.head-wrapper {
  z-index: 99;
  display: flex;
  align-items: center;
  position: fixed;
  left: 16px;
  top: 0;
  height: 65px;
}

.head-wrapper .head-menu {
  display: flex;
  align-items: center;
  height: 30px;
  width: 120px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.head-wrapper .head-menu .icon-fanhuijiantou {
  border-right: 1px solid #fff;
}

.page-detail .head-wrapper .head-menu .iconfont {
  flex: 1;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

.head-wrapper .head-menu .iconfont {
  flex: 1;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

/* vip升级页面 */

.svipCon {
  height: 42px;
  padding: 0 8px;
  margin: 10px 0px;
  background: #fae3bb;
  font-size: 13px;
  color: #b37400;
  border-radius: 5px;
}
.svipCon uni-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.svipCon .svipBtn {
  font-size: 12px;
  color: #b37400;
}

.custom-icon .van-icon {
  font-size: 20px; /* 修改图标的大小 */
}

.acea-row {
  display: flex;
  flex-wrap: wrap;
}

.detail_label {
  /* margin: 0 16px; */
  font-size: 13px;
  color: #82848f;
  margin-top: 12px;
}
.acea-row.row-between-wrapper {
  align-items: center;
  justify-content: space-between;
}

/* 规格类 */

.mobile-skuCate {
  margin-top: 15px;
  /* margin-bottom: 15px; */
  display: flex;
  align-items: center;
}

.mobile-SkuCateText {
  /* width: 100px; */
  padding-right: 4px;
  display: inline-block;
}
.mobile-skuCateText {
  height: 14px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #7f7f7f;
  line-height: 10px;
  /* margin-top: 9px; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mobile-skuItemWrapper {
  display: inline-block;
  vertical-align: top;
  /* width: 620px; */
  margin-left: -4.5px;
}

.mobile-skuItem {
  min-width: 36px;
  max-width: 300px;
  min-height: 36px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 9px;
  margin-right: 4.5px;
  margin-left: 4.5px;
  padding: 2px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.mobile-skuIcon {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.mobile-skuIcon + .skuValueName {
  max-width: 264px;
  padding-left: 4px;
}
.mobile-skuValueName {
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  color: #333;
  text-overflow: ellipsis;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 294px;
  line-height: 30px;
  vertical-align: middle;
}

.sku_select {
  background-color: #ffcc00; /* 这里是选中状态的背景颜色 */
  color: #ffffff;
}

/* 添加一个自定义类来覆盖默认的标题样式 */
.centered-dialog .el-dialog__title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
