<template>
  <div :style="!module.base.m ? 'margin-top:0' : ''">
    <div class="banner_con">
      <!-- 模糊背景层 -->
      <div class="blur-background">
        <img :src="currentBackground" />
      </div>

      <!-- 内容层 -->
      <div class="banner_content">
        <!-- <van-search v-model="value" placeholder="请输入搜索关键词" /> -->

        <!-- 搜索栏 -->
        <!-- <div class="search-bar">
          <div class="serch-box">
            <div class="serch-wrapper flex">
              <div class="skeleton-rect uninput" @click="toSearch">
                搜索商品

                <div class="iconfont icon-wxbsousuotuiguang">
                  <van-icon name="search" size="22" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <van-sticky>
          <div
            style="
              height: 35px;
              padding: 0 15px 0 15px;
              border-radius: 205px;
              color: #fff;
              font-size: 14px;
              margin: 10px 0px 5px 0px;
            "
          >
            <div class="skeleton-rect uninput" @click="toSearch">
              搜索商品

              <div class="iconfont icon-wxbsousuotuiguang">
                <van-icon name="search" size="22" />
              </div>
            </div>
          </div>
        </van-sticky>

        <!-- 轮播图 -->
        <van-swipe
          :show-indicators="false"
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          @change="onSwipeChange"
        >
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <!-- 轮播图内容 -->

            <a :href="item.clickUrl" target="_blank">
              <img :src="item.urlPath" />
            </a>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],

  data() {
    return {
      currentBackground: "", // 默认背景
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      bannerList: (state) => state.index.bannerList,
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = { banType: "MOBILE" };
      this.$store.dispatch("index/queryBannerList", data).then(() => {
        this.currentBackground = this.bannerList[0].urlPath;
      });
    },
    onSwipeChange(index) {
      // 当轮播图切换时，更新背景
      this.currentBackground = this.bannerList[index].urlPath;
    },

    // 跳转到搜索页面
    toSearch() {
      this.$router.push("/search");
    },
  },
  mounted() {
    // 初始化时设置第一张轮播图的背景
    if (this.bannerList.length > 0) {
      this.currentBackground = this.bannerList[0].urlPath;
    }
  },
};
</script>

<style>
.banner_con {
  /* 大div的样式 */
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.blur-background {
  /* 模糊背景层样式 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(40px); /* 设置模糊效果 */
  transition: background-image 0.5s ease; /* 添加平滑过渡效果 */
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center;
}

.search-bar {
  /* 搜索栏样式 */
  position: absolute;
  top: 10px; /* 根据需要调整，使搜索框出现在轮播图上方 */
  left: 0;
  right: 0;
  z-index: 2; /* 确保搜索栏在轮播图之上 */
}
.banner_content {
  /* 内容层样式，确保内容不受模糊效果影响 */
  position: relative;
  z-index: 1;
  /* padding-top: 40px;  */
}

.my-swipe img {
  /* 轮播图图片样式 */
  width: 100%;
  height: auto;
  display: block;
  border-radius: 15px;
  padding: 5px 10px 15px 10px;
}

/* 其他样式 */
.serch-box {
  height: 54px;
}
.serch-wrapper {
  align-items: center;
  padding: 0 15px;
  height: 100%;
}

.uninput {
  flex: 1;
  display: flex;
  align-items: center;
  /* width: 177px; */
  height: 35px;
  padding: 0 15px 0 15px;
  background: rgba(0, 0, 0, 0.16);
  border-radius: 205px;
  color: #fff;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}
.uninput .iconfont {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 12px;
}
</style>
