import request from '@/utils/request'



/**
 * 查询商品信息
 * @param {*} data 
 * @returns 
 */
export function queryProductList(data) {
    return request({
        url: '/api/queryProductList',
        method: 'post',
        data
    })
}


/**
 * 查询用户收藏商品列表
 * @param {*} data 
 * @returns 
 */
export function getCollectProductList(data) {
    return request({
        url: '/api/collect/query/productList',
        method: 'post',
        data
    })
}



/**
 * 收藏商品
 * @param {*} data 
 * @returns 
 */
export function addCollectProducts(data) {
    return request({
        url: '/api/collect/product',
        method: 'post',
        data
    })
}

/**
 * 删除用户收藏商品
 * @param {*} data 
 * @returns 
 */
export function deleteCollect(data) {
    return request({
        url: '/api/collect/delete/collect',
        method: 'post',
        data
    })
}



/**
 * 浏览商品
 * @param {*} data 
 * @returns 
 */
export function addBrowseProducts(data) {
    return request({
        url: '/api/browse/product',
        method: 'post',
        data
    })
}


/**
 * 查询用户浏览商品列表
 * @param {*} data 
 * @returns 
 */
export function getBrowseProductList(data) {
    return request({
        url: '/api/browse/query/productList',
        method: 'post',
        data
    })
}


// /**
//  * 删除用户浏览记录
//  * @param {*} data 
//  * @returns 
//  */
//  export function deleteCollect(data) {
//     return request({
//         url: '/api/collect/delete/collect',
//         method: 'post',
//         data
//     })
// }

/**
 * 查询商品信息
 * @param {*} data 
 * @returns 
 */
export function queryProductLevelPrice(data) {
    return request({
        url: '/api/user/product/levelPrice',
        method: 'post',
        data
    })
}


/**
 * 查询商品监控日志
 * @param {*} data 
 * @returns 
 */
export function queryProductMonitorLog(query) {
    return request({
        url: '/api/monitorLog/queryProductMonitorLog',
        method: 'get',
        params: query
    })
}


/**
 * 查询降价记录
 * @param {} query 
 * @returns 
 */
export function queryMarkdownProductList(query) {
    return request({
        url: '/api/monitorLog/queryMarkdownProductList',
        method: 'get',
        params: query
    })
}