import { render, staticRenderFns } from "./index_1.vue?vue&type=template&id=3b876d0b"
import script from "./index_1.vue?vue&type=script&lang=js"
export * from "./index_1.vue?vue&type=script&lang=js"
import style0 from "./index_1.vue?vue&type=style&index=0&id=3b876d0b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports